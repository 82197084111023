import Logo_Service from '../../assets/servicioAutorizado.png'
import Logo_Distribuidor from '../../assets/autorizado2.png'
import servicioMac from '../../assets/servicioMac.png'

export default function ServiceInfo() {
    return (
        <>
            <div className='row'>
                <a href="https://support.apple.com/es-mx/iphone/repair">
                    <img  src={servicioMac} alt='img-servicioMac' className='w-100 imgServicioMac' />
                </a>
            </div>
            <div className='row mt-2 mb-2'>
                <div className='col-sm-12 col-md text-center p-2'>
                    <img src={Logo_Service} alt='logoApple' className='img-logo-service' />
                </div>
                <div className=' col-sm-12 col-md text-center p-2 '>
                    <p className='p-0 m-0'>
                        Distribuidor autorizado
                        </p>
                    <p className='p-0 m-0'>
                        Adobe | Jamf Pro | Office | Graphisoft
                        </p>
                </div>
                <div className=' col-sm-12 col-md text-center p-2'>
                    <img src={Logo_Distribuidor} alt='logoApple' className='img-logo-service' />
                </div>
            </div>
            <style jsx>
                {`
            
            .imgServicioMac{
            height:auto;
            }
            `}
            </style>
        </>
    )
}