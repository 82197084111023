import centroServ from '../../assets/centroServicio.png'

export default function CentroServicio() {
    return (
        <>
            <div className='row mt-2 mb-2'>
                <div className='col m-0 p-0'>
                    <a href="https://support.apple.com/es-mx/iphone/repair">
                        <img src={centroServ} className='img-reparacion' alt='imagen reparacion' />
                    </a>
                </div>

            </div>
            <style jsx>
                {`
            .img-reparacion{
                width: 100%;
                height: auto;
            }
            `}
            </style>
        </>
    )
}