
//imports 
import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

//styles
import './App.css';

//components
import Home from './pages/Home'
//import Soon from './pages/Soon'

function App() {
  return (
    <Router>
      <Switch>
        <Route exact path="/" component={Home} />
        <Route exact path="/landing" component={Home} />

      </Switch>
    </Router>
  );
}
export default App;
