import React from 'react';

import { Container } from "react-bootstrap";
import { colors } from '../../styles/theme';
import MapShowLocation from '../MapShowLocation';

export default function Ubication() {

    return (
        <>
            <Container className='mt-2' id='ubication'>
                <div className='row '>
                    <div className='col-12  text-center text-infoUbication container-ubication p-3 rounded all-center'>
                        <div>
                            <p className='m-0 p-0'>Fray José de Arlegui 2325-L17</p>
                            <p className='m-0 p-0'>San Luis Potosí S.L.P.</p>
                            <p className='m-0 p-0'>Colonia Polanco 78220</p>
                            <a href='https://www.google.com/maps/place/Microcentro/@22.1480949,-101.012738,15z/data=!4m5!3m4!1s0x0:0x2f0f6c8143e87fe5!8m2!3d22.1480986!4d-101.0127081' className='color-blue'><strong>¿Cómo llegar?</strong></a>
                        </div>

                    </div>
                    <div className='col-12 rounded'>
                        <MapShowLocation />
                    </div>
                </div>
            </Container>
            <style jsx>
                {`
            .container-ubication{
                background:${colors.white};
                color:${colors.black}
            }
            .text-infoUbication{
                    font-size:x-large;
                    font-weight:500;
                }
            `}
            </style>
        </>
    )
}