import { useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import { MdEmail } from 'react-icons/md'
import { AiFillCaretUp, AiFillCaretDown } from 'react-icons/ai'
import { colors } from "../../styles/theme";

export default function FAQ() {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const faq = [
    { q: '¿Todas sus piezas son originales?', r: 'Si todas nuestras piezas de reparación son autorizadas por Apple' },
    { q: 'Reparan pantallas de iPhone?', r: 'Si, casí todos los iPhone tienen posibilidad de cambiar la pantalla.' },
    { q: '¿Cuánto tiempo tardan en reparar mi computadora?', r: 'Promedio de 1 a 3 dias hábiles' },
    { q: '¿Tiempo de reparación para un iPhone?', r: 'Dependiendo del caso puede haber reparaciones que se puedan completar ese mismo dia o como máximo 3 dias hábiles' },
    { q: '¿Se puede reparar mi iPad?', r: 'Si tu equipo lleva menos de un año desde su compra o cuenta con servicio AppleCare, puedes acudir con nosotros, si tu equipo no cuenta con esta cobertura se aplicará la tarifa de servicio fuera de garantía.' },
    { q: '¿Cuentan con soporte para mi equipo Apple Watch?', r: 'Claro, podemos apoyarte si cuentas con cobertura de Garantia o fuera de garantia.' },
    { q: '¿Puedo tramitar mi AppleCare aquí?', r: 'Si, puedes traer tu equipo para validar su cobertura, recuerda realizar una copia de seguridad de tu informacion previamente.' },
    { q: '¿Qué formas de pago tienen?', r: 'Para su mayor comodidad puede hacer pago con transferencia, con tarjeta de crédito o débito y también aceptamos pago en efectivo' },
    { q: '¿Es obligatorio programar una cita para que reciban mi equipo?', r: 'Podemos recibirte en cualquier momento dentro de nuestro horario laboral de Lunes a Viernes de 9:00 am - 5:00 pm y sábado de 10:00 am- 1:00 pm, o programas una cita en la pagina de Apple.' },
    { q: '¿Cómo puedo respaldar mi información?', r: 'Desde tu cuenta de iCloud puedes hacer de manera automática un respaldo de tus dispositivos iOS y iPadOS cuando están conectados a la corriente y a una red Wi-Fi. Para tu computadora puedes realizar un respaldo con Time Machine o almacenar archivos en iCloud. https://support.apple.com/es-mx/mac-backup' },


  ]
  const [question, setQuestion] = useState(0);

  const nextQuestion = () => {
    setQuestion(question < faq.length - 2 ? question + 2 : 0)
  };

  const beforeQuestion = () => {
    setQuestion(question > 0 ? question - 2 : faq.length - 2)
  };

  return (
    <>
      <div onClick={handleShow}>
        FAQ
      </div>
      <Modal show={show} onHide={handleClose}
        onKeyDown={e => e.stopPropagation()}
        onClick={e => e.stopPropagation()}
        onFocus={e => e.stopPropagation()}>
        <Modal.Header closeButton className='border-0 mb-0 pb-0 rounded-pill'>
          <Modal.Title className='all-center w-100'>FAQ</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p className='text-center text-infor'>
            Si no encuentras tu pregunta, mándanos un mensaje y te la resolveremos en breve
          </p>
          <div className='w-100 d-flex justify-content-center m-2'>
            <Button variant="light" onClick={nextQuestion} className='btn-next'><AiFillCaretUp /></Button>
          </div>
          <div className='w-100 '>
            <p className='text-center m-0 p-0'><strong>{faq[question].q}</strong></p>
            <p className='text-center'>{faq[question].r}</p>
            <p className='text-center mt-2 mb-0 p-0'><strong>{faq[question + 1].q}</strong></p>
            <p className='text-center'>{faq[question + 1].r}</p>
          </div>
          <div className='w-100 d-flex justify-content-center m-2'>
            <Button variant="light" onClick={beforeQuestion} className='btn-next'><AiFillCaretDown /></Button>
          </div>

          <Form className='mt-3 p-4' action="https://formsubmit.co/b425d4541fc1673897052995337fc10c" method="POST">
            <Form.Group className="mb-2">
              <Form.Control as="textarea" rows={4} name="mensaje" required placeholder="Si tu pregunta no aparece, escribela aquí y dejanos tu correo para que podamos apoyarte lo más pronto posible" />
            </Form.Group>
            <Form.Group className="mb-3">
              <div className='container'>
                <div className='row'>
                  <div className='col-2  text-center'>
                    <MdEmail />
                  </div>
                  <div className='col'>
                    <Form.Control type="email" name="email" required placeholder="Correo electrónico" />
                  </div>
                </div>
              </div>
            </Form.Group>
            <Button variant="dark" type="submit" className='btn-block'>
              Enviar
            </Button>
          </Form>
        </Modal.Body>

      </Modal>
      <style jsx>
        {`
          
          .text-infor{
            font-weight:500;
          }
          .btn-next{
            color:${colors.black};
            background:${colors.white};
          }
        `}
      </style>

    </>
  );
}

