import React from 'react';

import { colors } from '../../styles/theme';
import User from '../../assets/history.png'

export default function History() {

    return (
        <>

            <div className='row p-2'>
                <div className='col-sm-12 col-md-5 d-flex justify-content-center'>
                    <img className='img-history text-center' src={User} alt='user' />
                </div>
                <div className='col-sm-12 col-md-7  m-0 p-0 all-center'>
                    <div className='row  p-0 '>
                        <div className='col '>
                            <h2 className='font-weight-light mt-4 text-center'>- 35 años de experiencia con productos Apple. </h2>
                            <h2 className='font-weight-light mt-4 text-center'>- Soluciones empresariales y escolares.</h2>
                            <h2 className='font-weight-light mt-4 text-center'>- Atención personalizada y de excelencia.</h2>
                            <h2 className='font-weight-light mt-4 text-center'>- Más de 10,000 dispositivos Apple reparados.</h2>
                        </div>
                    </div>
                </div>

            </div>
            <style jsx>
                {`
            .container-history{
                width:80%;
                background:${colors.black50};
                color:${colors.white}
            }
            .img-history{
                height:auto;
                width:80%;
                object-fit: cover;
            }
            `}
            </style>
        </>
    )
}