import Contactanos from "../Contactanos";
import { colors } from "../../styles/theme";
import { Button } from "react-bootstrap";


export default function ServiceOptions() {
    return (
        <>
            <div className='row  mt-5  p-4'>
                <div className='col-12  border-secondary  all-center'>
                    <h2 className='text-center'>¿Quieres cotizar un equipo Apple?</h2>
                </div>
                <div className='col-12  border-secondary  all-center'>
                    <Contactanos option='Información' />
                </div>
            </div>
            <div className='row  mt-5  p-4'>
                <div className='col-12 border-secondary  all-center'>
                    <h2 className='text-center'>¿Tu Mac está fallando o tu iPhone está roto?</h2>
                </div>
                <div className='col-12  border-secondary  all-center'>
                    <Button className='btn-information' href="https://getsupport.apple.com/?caller=arshw&locale=es_MX">Agenda una cita</Button>
                </div>
            </div>
            <div className='row  mt-5  p-4'>
                <div className='col-12 border-secondary  all-center'>
                    <h2 className='text-center'>¿Necesitas una licencia de software u otro equipo?</h2>
                </div>
                <div className='col-12  border-secondary  all-center'>
                    <Contactanos option='Contactanos' />
                </div>
            </div>

            <style jsx>
                {`
          .btn-information{
                background:${colors.funBlue};
                color:${colors.white};
                border-radius:20px;
            }
        `}
            </style>
        </>
    )
}