import React from 'react';
import { Nav, Navbar } from "react-bootstrap";
import Logo_White from '../../assets/logo_white.png'
import { colors } from '../../styles/theme';
import Contactanos from '../Contactanos';
import FAQ from '../FAQ';


export default function Header() {
    return (
        <>
            <Navbar variant="dark" expand="lg" className='header'>
                <Navbar.Brand href="#home">
                    <img className="mx-auto" alt='...' src={Logo_White} ></img>
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <div className='w-100 d-flex justify-content-center'>
                    <div className='text-center'>
                        <p className='m-0 p-0'><strong>HORARIOS</strong></p>
                        <p className='m-0 p-0'><strong>Lunes a viernes 9:00 a 18:30</strong></p>
                        <p className='m-0 p-0'><strong>Sábado de 10:00 a 13:00</strong></p>
                    </div>
                </div>
                <Navbar.Collapse className="justify-content-end" >
                    <Nav className=" d-flex justify-content-end text-white">
                        <Nav.Link href="#home" className='text-white'>
                            <Contactanos option='CONTACTO' />
                        </Nav.Link>
                        <Nav.Link href="#home" className='text-white'>
                            <FAQ />
                        </Nav.Link>
                        <Nav.Link href="#ubication" className='text-white'>UBICACIÓN</Nav.Link>
                    </Nav>
                </Navbar.Collapse>
            </Navbar>
            <style jsx>
                {`
            .header{
                background:${colors.black};
                color:${colors.white};
            }
            img{
                height:50px;
            }
            .text-white{
                color:${colors.white}
            }
            `}
            </style>
        </>
    )
}