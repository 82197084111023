export default function Options() {
    return (
        <>
            <div className='row m-2 p-0'>
                <div className='col text-center'><h5 className='font-weight-light'>iPad</h5></div>
                <div className='col text-center'><h5 className='font-weight-light'>iPhone</h5></div>
                <div className='col text-center'><h5 className='font-weight-light'>iMac</h5></div>

            </div>
            <div className='row m-2 p-0'>
                <div className='col text-center'><h5 className='font-weight-light'>MacBook</h5></div>
                <div className='col text-center'><h5 className='font-weight-light'>Apple Watch</h5></div>
                <div className='col text-center'><h5 className='font-weight-light'>Apple TV</h5></div>
            </div>
        </>
    )
}