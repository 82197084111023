
import { Navbar } from 'react-bootstrap'
import { colors } from '../../styles/theme'
import Logo_White from '../../assets/logo_white.png'

export default function Footer() {
    return (
        <>
            <Navbar className='footer-content mt-5'>
                <div className='row w-100'>
                    <div className='col-12 all-center'>
                        <img className="mx-auto " alt='...' src={Logo_White} ></img>
                    </div>
                    <div className='col-sm-12 col-md-12 text-center p-3'>
                        <p className='text-center'>{'InHouse Dev 2022'}</p>
                    </div>
                </div>
            </Navbar>


            <style jsx>
                {`
                .footer-content{
                    background:${colors.black};
                    color:${colors.white};
                }
                
                `}
            </style>

        </>
    )

}