import { useState } from "react";
import { Carousel, Image } from "react-bootstrap";
import Slider1 from '../../assets/mbPro.png';
import Slider2 from '../../assets/imac.png';
import Slider3 from '../../assets/macStudio.png';
import Slider4 from '../../assets/mbAir.png'

export default function ImageCarousel({ img1 = Slider1, img2 = Slider2, img3 = Slider3, img4 = Slider4 }) {
    const [index, setIndex] = useState(0);

    const handleSelect = (selectedIndex, e) => {
        setIndex(selectedIndex);
    };

    return (
        <>
            <Carousel activeIndex={index} onSelect={handleSelect}>
                <Carousel.Item className='text-center' >
                    <Image src={img1} className="img-carousel  w-100"
                    />
                </Carousel.Item>
                <Carousel.Item className='text-center' >
                    <Image src={img2} className="img-carousel w-100"
                    />
                </Carousel.Item>
                <Carousel.Item className='text-center'>
                    <Image src={img3} className="img-carousel w-100"
                    />
                </Carousel.Item>
                <Carousel.Item className='text-center'>
                    <Image src={img4} className="img-carousel w-100"
                    />
                </Carousel.Item>
            </Carousel>
            <style jsx>
                {`
            
            .img-carousel{
            height:auto;
            }
            `}
            </style>
        </>
    );
}

