import { useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import { MdEmail } from 'react-icons/md'
import { FaPhoneAlt } from 'react-icons/fa'
import { BsFillPersonFill } from 'react-icons/bs'
import { colors } from "../../styles/theme";

export default function Contactanos({ option, showModal }) {
  const [show, setShow] = useState(showModal || false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
      {!option &&
        <div onClick={handleShow} className='btn-contact all-center'>
          <span className="badge bg-secondary text-white p-2">¡Contactanos!</span><div className='badge bg-primary pt-2 pb-2 pl-3 pr-3 rounded-circle'><h2 className='text-white'><FaPhoneAlt /></h2></div>
        </div>
      }
      {option && option !== 'CONTACTO' &&
        <Button className='btn-information' onClick={handleShow} >
          {option}
        </Button>
      }
      {option === 'CONTACTO' &&
        <div onClick={handleShow}>
          CONTACTO
      </div>
      }

      <Modal show={show} onHide={handleClose}
        onKeyDown={e => e.stopPropagation()}
        onClick={e => e.stopPropagation()}
        onFocus={e => e.stopPropagation()}>
        <Modal.Header closeButton className='border-0 mb-0 pb-0 rounded-pill'>
          <Modal.Title className='all-center w-100'>
            ¡Gracias por ponerte en contacto con nosotros!</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p className='text-center'>Envíanos un correo a <a href="mailto:microcentro@mac.com"><strong>microcentro@mac.com</strong></a> ó déjanos tus datos y nos pondremos en contacto contigo</p>
          <p className='text-center m-0 p-0'>Teléfonos de contacto Microcentro</p>
          <div className='row'>
            <div className='col'>
              <a href='tel:4448128428'><p className='text-center text-phone'><FaPhoneAlt className='text-primary mr-1' />444 8128428</p></a>
            </div>
            <div className='col'>
              <a href='tel:4448123935'><p className='text-center text-phone'><FaPhoneAlt className='text-primary mr-1' />444 8123935</p></a>
            </div>

          </div>
          <Form className='mt-3 p-4' action="https://formsubmit.co/b425d4541fc1673897052995337fc10c" method="POST">
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <div className='container'>
                <div className='row'>
                  <div className='col-2 text-center'>
                    <BsFillPersonFill />
                  </div>
                  <div className='col'>
                    <Form.Control type="text" name="name" required placeholder="Nombre" />
                  </div>
                </div>
              </div>

            </Form.Group>
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <div className='container'>

                <div className='row'>
                  <div className='col-2  text-center'>
                    <FaPhoneAlt />
                  </div>
                  <div className='col'>
                    <Form.Control type="text" name="telefono" required placeholder="Teléfono" />
                  </div>
                </div>
              </div>
            </Form.Group>
            <Form.Group className="mb-3" controlId="formBasicEmail2">
              <div className='container'>
                <div className='row'>
                  <div className='col-2  text-center'>
                    <MdEmail />
                  </div>
                  <div className='col'>
                    <Form.Control type="email" name="email" required placeholder="Correo electrónico" />
                  </div>
                </div>
              </div>
            </Form.Group>
            <Form.Group className="mb-2" controlId="formBasicEmail">
              <Form.Control as="textarea" rows={2} name="mensaje" required placeholder="Deja tu mensaje" />
            </Form.Group>
            <Button variant="dark" type="submit" className='btn-block'>
              Enviar
  </Button>
          </Form>
        </Modal.Body>

      </Modal>
      <style jsx>
        {`
          .btn-contact{
            position: fixed;
            bottom: 5vh;
            right:0;
            z-index:1;
            cursor:pointer;
            margin:10px;
          }
          .text-phone{
            font-weight:500;
          }
          .btn-information{
                background:${colors.funBlue};
                color:${colors.white};
                border-radius:20px;
            }
        `}
      </style>


    </>
  );
}

