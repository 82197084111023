import React from 'react';
import Footer from '../../components/Footer';
import Header from '../../components/Header';
import History from '../../components/History';
import ImageCarousel from '../../components/ImageCarousel';
import Ubication from '../../components/Ubication';
import ServiceInfo from '../../components/ServiceInfo';
import Options from '../../components/Options';
import ServiceOptions from '../../components/ServiceOptions';
import Contactanos from '../../components/Contactanos';
import m1 from '../../assets/ip9.png';
import m2 from '../../assets/ipa.png';
import m3 from '../../assets/ipm.png';
import m4 from '../../assets/ac1.png'
import CentroServicio from '../../components/centroServicio';
const Home = () => {
    return (
        <>
            <Header />
            <ImageCarousel />
            <Contactanos />
            <Options />
            <ServiceInfo />
            <ServiceOptions />
            <ImageCarousel img1={m1} img2={m2} img3={m3} img4={m4}/>
            <CentroServicio />
            <Ubication />
            <History />
            <Footer />


        </>
    );
};

export default Home;