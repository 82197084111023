import React from 'react'
import { GoogleMap, LoadScript, Marker } from '@react-google-maps/api'

export default function MapShowLocation({ lat, lng }) {
    const mapStyles = {
        height: '240px',
        width: '100%'
    }

    const defaultCenter = {
        lat: lat || 22.1480951,
        lng: lng || -101.0127667
    }

    return (
        <LoadScript googleMapsApiKey='AIzaSyAtYi9RKc9QDt0xID8vlYfgj_uaGtud-Q0'>
            <GoogleMap mapContainerStyle={mapStyles} zoom={16} center={defaultCenter}>
                <Marker position={defaultCenter} />
            </GoogleMap>
        </LoadScript>
    )
}
